import SearchPageHeading from './heading';
import { CirclePlus, Layers } from 'lucide-react';
import { Card, CardContent } from '../ui/card';
import { Separator } from '../ui/separator';
import { useParams } from 'react-router-dom';
import { useThreadStore } from 'src/store/useThreadStore';
import { useState } from 'react';

type TProps = {
  list: [string];
};
export default function Releted({ list }: TProps) {
  const { sessionId } = useParams();
  const {
    askFollowUpQuestions,
    isPrivate,
    modelName,
    temperatureValue,
    isAdvance,
  } = useThreadStore();

  const handleClick = async (question: string) => {
    await askFollowUpQuestions(
      sessionId as string,
      {
        question,
        modelName: isPrivate
          ? modelName
            ? modelName === 'mistral-large'
              ? 'mistral.mistral-large-2402-v1:0'
              : modelName === 'claude-3-sonnet'
                ? 'anthropic.claude-3-sonnet-20240229-v1:0'
                : modelName
            : 'mistral.mistral-large-2402-v1:0'
          : 'gpt-3.5-turbo',
        temperature: isPrivate ? temperatureValue : 0.3,
      },
      isAdvance,
    );
  };

  return (
    <div className="flex flex-col gap-3">
      <SearchPageHeading title="Your Questions Co-Pilot" Icon={<Layers />} />
      <Card className="!p-0">
        <CardContent className="relative w-full !p-0 ">
          {list?.length &&
            list.map((item, i) => (
              <div key={i} onClick={() => handleClick(item)}>
                <div className="flex cursor-pointer items-center gap-2 px-4 py-3 text-center text-black dark:text-white">
                  <div>
                    <CirclePlus size={14} className="dark:text-secondary" />
                  </div>
                  <span className="text-left">{item}</span>
                </div>
                {i < list.length - 1 && (
                  <Separator className="relative w-full" />
                )}
              </div>
            ))}
        </CardContent>
      </Card>
    </div>
  );
}
