import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import SubmitButton from './submit-button';
import { verifyOtp } from 'src/services/authService';
import { toast } from 'sonner';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from '../ui/input-otp';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMyStore } from 'src/context/store';
import { ModalNames } from 'src/@types/types';
import { VerifyOtpData, VerifyOtpSchema } from 'src/lib/schema/verify-otp';
import { Button } from '../ui/button';
type Props = {
  afterSignInAction?: () => void;
};
const VerifyOtp: FC<Props> = ({ afterSignInAction }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { toggleModal } = useMyStore((state) => state);
  const email = localStorage.getItem('email') ?? undefined;
  const navigate = useNavigate();
  // React Hook Form
  const form = useForm<VerifyOtpData>({
    resolver: zodResolver(VerifyOtpSchema),
    defaultValues: {
      email,
      otp: '',
    },
  });

  const onOtp = async (values: VerifyOtpData) => {
    setLoading(true);
    const res: TResponse = await verifyOtp(values);
    if (res.status) {
      toast.success(res.message);
      toggleModal(ModalNames.SIGNIN, true);
      setLoading(false);
    } else {
      toast.error(res.message);
      setLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form
        className="flex min-h-full flex-col justify-between space-y-2 dark:text-primary"
        onSubmit={form.handleSubmit(onOtp)}
      >
        <div className="h-52">
          <FormField
            control={form.control}
            name="otp"
            render={({ field }) => (
              <FormItem className="max-w-fit space-y-6 opacity-80">
                <FormLabel className="">Type the 6 digit code below</FormLabel>
                <FormControl className="">
                  <InputOTP maxLength={6} {...field}>
                    <InputOTPGroup>
                      <InputOTPSlot index={0} />
                    </InputOTPGroup>
                    <InputOTPGroup>
                      <InputOTPSlot index={1} />
                    </InputOTPGroup>
                    <InputOTPGroup>
                      <InputOTPSlot index={2} />
                    </InputOTPGroup>
                    <InputOTPGroup>
                      <InputOTPSlot index={3} />
                    </InputOTPGroup>
                    <InputOTPGroup>
                      <InputOTPSlot index={4} />
                    </InputOTPGroup>
                    <InputOTPGroup>
                      <InputOTPSlot index={5} />
                    </InputOTPGroup>
                  </InputOTP>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex gap-2">
          <Button
            variant="outline"
            type="button"
            className="flex w-full items-center gap-2 py-6"
            onClick={() => toggleModal(ModalNames.SIGNUP, true)}
          >
            Cancel
          </Button>

          <SubmitButton
            pending={loading}
            className="flex w-full items-center gap-2 bg-gradient-to-r from-[#9747FF] to-[#E91E63] py-6 text-white"
          >
            Verify Otp
          </SubmitButton>
        </div>
      </form>
    </Form>
  );
};

export default VerifyOtp;
