import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ModalNames } from 'src/@types/types';
import { useMyStore } from 'src/context/store';

type Props = {
  modal: ModalNames;
  path: string;
  children: React.ReactNode;
};

const AuthLink: FC<Props> = ({ modal, path, children }) => {
  const { isModalOpen, toggleModal } = useMyStore((state) => state);

  const onButtonClick = () => {
    toggleModal(modal, true);
  };
  return isModalOpen ? (
    <button
      type="button"
      className="bg-gradient-to-r from-[#9747FF] to-[#E91E63] bg-clip-text text-xs font-medium text-transparent"
      onClick={onButtonClick}
    >
      {children}
    </button>
  ) : (
    <Link
      to={path}
      className="bg-gradient-to-r from-[#9747FF] to-[#E91E63] bg-clip-text text-xs font-medium text-transparent"
    >
      {children}
    </Link>
  );
};

export default AuthLink;
