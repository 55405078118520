import { zodResolver } from '@hookform/resolvers/zod';
import { EyeIcon, EyeOff } from 'lucide-react';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { useNavigate } from 'react-router-dom';
import { Input } from '../ui/input';
import { useMyStore } from 'src/context/store';
import SubmitButton from './submit-button';
import { ModalNames } from 'src/@types/types';
import { resetPassword } from 'src/services/authService';
import { toast } from 'sonner';

import { Button } from '../ui/button';
import {
  ResetPasswordFormData,
  ResetPasswordFormSchema,
} from 'src/lib/schema/reset-password';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '../ui/input-otp';

type Props = {
  afterSignInAction?: () => void;
};

const ResetPassword: FC<Props> = ({ afterSignInAction }) => {
  const { setUserStatus, toggleModal, setUser } = useMyStore((state) => state);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showCPassword, setShowCPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const onShowPasswordClick = () => {
    setShowPassword((s) => !s);
  };
  const onShowCPasswordClick = () => {
    setShowCPassword((s) => !s);
  };
  const emaill = localStorage.getItem('email') ?? undefined;

  const form = useForm<ResetPasswordFormData>({
    resolver: zodResolver(ResetPasswordFormSchema),
    defaultValues: {
      email: emaill,
      otp: '',
      new_password: '',
    },
  });
  const onLogin = async (values: ResetPasswordFormData) => {
    setLoading(true);
    try {
      const res: TResponse = await resetPassword(values);
      if (res.status) {
        toast.success(res.message);
        toggleModal(ModalNames.SIGNIN, true);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error('Error during password reset', error); // Additional error logging
      toast.error('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form
        className="flex h-full flex-col justify-between space-y-2 dark:text-primary"
        onSubmit={form.handleSubmit(onLogin)}
      >
        <div className="h-56">
          <FormField
            control={form.control}
            name="otp"
            render={({ field }) => (
              <FormItem className="relative pb-5">
                <FormLabel>Authorization Code</FormLabel>
                <FormControl className="">
                  <InputOTP maxLength={6} {...field}>
                    <InputOTPGroup>
                      <InputOTPSlot index={0} />
                    </InputOTPGroup>
                    <InputOTPGroup>
                      <InputOTPSlot index={1} />
                    </InputOTPGroup>
                    <InputOTPGroup>
                      <InputOTPSlot index={2} />
                    </InputOTPGroup>
                    <InputOTPGroup>
                      <InputOTPSlot index={3} />
                    </InputOTPGroup>
                    <InputOTPGroup>
                      <InputOTPSlot index={4} />
                    </InputOTPGroup>
                    <InputOTPGroup>
                      <InputOTPSlot index={5} />
                    </InputOTPGroup>
                  </InputOTP>
                </FormControl>
                <FormMessage className="absolute bottom-0 text-xs" />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="new_password"
            render={({ field }) => (
              <FormItem className="relative pb-5">
                <FormLabel>New Password</FormLabel>
                <div className="flex">
                  <FormControl className="rounded-br-none rounded-tr-none py-6">
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      {...field}
                      inputMode="text"
                      placeholder="**********"
                      autoComplete="current-password"
                    />
                  </FormControl>
                  <button
                    type="button"
                    onClick={onShowPasswordClick}
                    className="flex cursor-pointer items-center justify-center rounded-br-md rounded-tr-md border border-l-0 border-input px-2"
                  >
                    {showPassword ? <EyeIcon /> : <EyeOff />}
                  </button>
                </div>
                <FormMessage className="absolute bottom-0 text-xs" />
              </FormItem>
            )}
          />
        </div>
        <div className="flex gap-2">
          <Button
            variant="outline"
            type="button"
            className="flex w-full items-center gap-2 py-6"
            onClick={() => toggleModal(ModalNames.FORGOT_PASSWORD, true)}
          >
            Cancel
          </Button>
          <SubmitButton
            pending={loading}
            className="flex w-full items-center gap-2 bg-gradient-to-r from-[#9747FF] to-[#E91E63] py-6 text-white "
          >
            Save
          </SubmitButton>
        </div>
      </form>
    </Form>
  );
};

export default ResetPassword;
