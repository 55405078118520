import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import SubmitButton from './submit-button';
import { forgotPassword, signIn } from 'src/services/authService';
import { toast } from 'sonner';
import { useMyStore } from 'src/context/store';
import { ModalNames } from 'src/@types/types';
import { Button } from '../ui/button';
import {
  ForgotPasswordData,
  ForgotPasswordSchema,
} from 'src/lib/schema/forgot-password';
type Props = {
  afterSignInAction?: () => void;
};
const ForgotPassword: FC<Props> = ({ afterSignInAction }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { toggleModal } = useMyStore((state) => state);

  // React Hook Form
  const form = useForm<ForgotPasswordData>({
    resolver: zodResolver(ForgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (values: ForgotPasswordData) => {
    setLoading(true);
    const res: TResponse = await forgotPassword(values);
    if (res.status) {
      toast.success(res.message);
      toggleModal(ModalNames.RESET_PASSWORD, true);
      localStorage.setItem('email', values.email);
      setLoading(false);
    } else {
      toast.error(res.message);
      setLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form
        className="flex h-full flex-col justify-between space-y-2 dark:text-primary"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="h-30">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="relative pb-5">
                <FormLabel>Email address</FormLabel>
                <FormControl className="py-6">
                  <Input
                    {...field}
                    inputMode="email"
                    placeholder="Enter email"
                    autoComplete="email"
                  />
                </FormControl>
                <FormMessage className="absolute bottom-0 text-xs" />
              </FormItem>
            )}
          />
        </div>
        <div className="flex gap-2">
          <Button
            variant="outline"
            type="button"
            className="flex w-full items-center gap-2 py-6"
            onClick={() => toggleModal(ModalNames.SIGNIN, true)}
          >
            Cancel
          </Button>

          <SubmitButton
            pending={loading}
            className="flex w-full items-center gap-2 bg-gradient-to-r from-[#9747FF] to-[#E91E63] py-6 text-white "
          >
            Send Otp
          </SubmitButton>
        </div>
      </form>
    </Form>
  );
};

export default ForgotPassword;
