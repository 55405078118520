import { LucideIcon } from 'lucide-react';
import { Button, ButtonProps } from '../ui/button';
import { SheetClose } from '../ui/sheet';
import { cn } from 'src/lib/utils';

interface SidebarButtonProps extends ButtonProps {
  icon?: LucideIcon | React.ComponentType<any>;
}

// Define the type guard function
function isLucideIcon(component: any): component is LucideIcon {
  return (
    component &&
    typeof component === 'function' &&
    component.displayName?.startsWith('Lucide')
  );
}
export function SidebarButton({
  icon: Icon,
  className,
  children,
  ...props
}: SidebarButtonProps) {
  return (
    <Button
      variant="default"
      className={cn(`justify-start ${children ? 'gap-2' : ''}`, className)}
      {...props}
    >
      {Icon && isLucideIcon(Icon) && <Icon size={20} />}
      {Icon && !isLucideIcon(Icon) && <Icon />}
      <span>{children}</span>
    </Button>
  );
}

export function SidebarButtonSheet(props: SidebarButtonProps) {
  return (
    <SheetClose asChild>
      <SidebarButton {...props} />
    </SheetClose>
  );
}

export function SidebarNewThreadButton({
  icon: Icon,
  className,
  children,
  ...props
}: SidebarButtonProps) {
  return (
    <Button
      className={cn(`justify-start ${children ? 'gap-2' : ''}`, className)}
      {...props}
    >
      {children ?? <span>{children}</span>}
      {Icon && <Icon size={20} />}
    </Button>
  );
}
