import HomeSearch from 'src/components/home/home-search';
import Recomanded from 'src/components/home/recomanded';
import { useMyStore } from 'src/context/store';
import { useThreadStore } from 'src/store/useThreadStore';

export default function Home() {
  const { isPrivate } = useThreadStore();
  const { drawer } = useMyStore((state) => state);
  return (
    <>
      <header
        className={`fixed left-0 top-0 z-10 max-sm:hidden ${drawer ? 'pl-[100px]' : 'pl-[300px]'} w-full  py-4 transition-all duration-300 ease-in-out dark:bg-background`}
      >
        <div className="lg:px-30 flex items-center justify-between text-center max-sm:px-4  sm:px-8 md:px-14 xl:px-32">
          {isPrivate ? (
            <div className="flex gap-2 rounded-md border border-[#8D5CC1] bg-accent px-2 py-[3px] text-center text-lg dark:border-[#8D5CC1] dark:bg-foreground dark:text-accent-foreground">
              <span className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M7.5 8.99774H10.5V11.2477H7.5V8.99774ZM9.75 7.49774C9.75 7.29883 9.67098 7.10806 9.53033 6.96741C9.38968 6.82676 9.19891 6.74774 9 6.74774C8.80109 6.74774 8.61032 6.82676 8.46967 6.96741C8.32902 7.10806 8.25 7.29883 8.25 7.49774V8.24774H9.75V7.49774Z"
                    fill="#8D5CC1"
                  />
                  <path
                    d="M15.375 3.74776C13.1936 3.74776 10.323 3.01201 9.3 1.64776C9.26359 1.60341 9.2178 1.56768 9.16593 1.54315C9.11405 1.51861 9.05738 1.50589 9 1.50589C8.94262 1.50589 8.88595 1.51861 8.83407 1.54315C8.7822 1.56768 8.73641 1.60341 8.7 1.64776C7.677 3.01164 4.80638 3.74776 2.625 3.74776C2.52554 3.74776 2.43016 3.78727 2.35984 3.8576C2.28951 3.92792 2.25 4.0233 2.25 4.12276V8.99776C2.25 13.1213 5.83087 15.6075 8.89725 16.4831C8.96436 16.5026 9.03564 16.5026 9.10275 16.4831C12.1691 15.6075 15.75 13.1213 15.75 8.99814V4.12314C15.75 4.02368 15.7105 3.9283 15.6402 3.85797C15.5698 3.78764 15.4745 3.74776 15.375 3.74776ZM11.25 11.6228C11.25 11.7222 11.2105 11.8176 11.1402 11.8879C11.0698 11.9583 10.9745 11.9978 10.875 11.9978H7.125C7.02554 11.9978 6.93016 11.9583 6.85983 11.8879C6.78951 11.8176 6.75 11.7222 6.75 11.6228V8.62276C6.75 8.5233 6.78951 8.42792 6.85983 8.3576C6.93016 8.28727 7.02554 8.24776 7.125 8.24776H7.5V7.49776C7.5 7.09994 7.65804 6.7184 7.93934 6.4371C8.22064 6.1558 8.60218 5.99776 9 5.99776C9.39782 5.99776 9.77936 6.1558 10.0607 6.4371C10.342 6.7184 10.5 7.09994 10.5 7.49776V8.24776H10.875C10.9745 8.24776 11.0698 8.28727 11.1402 8.3576C11.2105 8.42792 11.25 8.5233 11.25 8.62276V11.6228Z"
                    fill="#8D5CC1"
                  />
                </svg>
              </span>
              <p className="text-[15px] font-semibold text-accent-foreground">
                Private
              </p>
            </div>
          ) : (
            <div className="flex gap-3 rounded-md border border-[#878787]  px-2 py-[3px] text-center text-lg dark:border-[#8D5CC1] dark:bg-foreground dark:text-accent-foreground">
              <span className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.7954 4.29545C10.7954 6.25356 9.20803 7.84091 7.24993 7.84091C5.29183 7.84091 3.70448 6.25356 3.70448 4.29545C3.70448 2.33735 5.29183 0.75 7.24993 0.75C9.20803 0.75 10.7954 2.33735 10.7954 4.29545ZM7.25 8.43182C9.01249 8.47751 10.7402 8.93269 12.2964 9.76136C12.7346 9.98423 13.1024 10.3242 13.359 10.7436C13.6156 11.1629 13.751 11.6452 13.75 12.1368V12.5682C13.75 12.8816 13.6255 13.1822 13.4039 13.4039C13.1822 13.6255 12.8816 13.75 12.5682 13.75H1.93182C1.61838 13.75 1.31778 13.6255 1.09615 13.4039C0.874513 13.1822 0.75 12.8816 0.75 12.5682V12.125C0.751201 11.6354 0.887564 11.1556 1.14406 10.7385C1.40055 10.3215 1.76722 9.98332 2.20364 9.76136C3.75984 8.93269 5.48751 8.47751 7.25 8.43182Z"
                    fill="#878787"
                  />
                </svg>
              </span>
              <p className="text-[15px] font-semibold text-[#878787]">Public</p>
            </div>
          )}
        </div>
      </header>
      <div className="px-md mx-auto flex h-full w-full max-w-screen-md items-center justify-center sm:px-6 md:px-8">
        <div className="mb-lg md:mb-xl flex w-full flex-col items-center gap-4 max-sm:px-[17px]">
          <h1 className="mb-2 text-lg font-normal text-lighttext dark:text-accent-foreground max-sm:text-2xl sm:text-xl md:text-xl xl:text-4xl">
            Search
          </h1>
          <HomeSearch />
          {/* <span className="text-primary-foreground">Try searching...</span> */}
          {/* <Recomanded /> */}
        </div>
      </div>
    </>
  );
}
