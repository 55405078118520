import { ReactNode } from 'react';
import AuthUIWrapper from './ui-wrapper';

type TProps = {
  children: ReactNode;
  title: string;
  description?: string;
};
export default function Authwrapper({ children, title, description }: TProps) {
  return (
    <div className="grid min-h-[470px] max-w-screen-sm gap-0 p-0 lg:max-w-screen-md lg:grid-cols-[60%,40%]">
      <div className="col-span-1 flex  flex-col justify-between max-lg:py-20 max-sm:py-2">
        <div className="w-full space-y-5 p-5 py-8">
          <div className="">
            <h1 className="text-4xl font-normal text-secondary max-sm:text-4xl">
              {title}
            </h1>
            <p className="mt-2 tracking-wide text-primary">{description}</p>
          </div>
          {children}
        </div>
      </div>
      <div className="bg-authimage col-span-1 hidden h-full self-center rounded-r-md bg-cover bg-center bg-no-repeat py-20 lg:block">
        <AuthUIWrapper />
      </div>
    </div>
  );
}
