type TIcons = {
  name:
    | 'withname'
    | 'logo'
    | 'logoDark'
    | 'source'
    | 'related'
    | 'CirclePlus'
    | 'rightArrow'
    | 'topArrow'
    | 'copy'
    | 'open'
    | 'close'
    | 'search'
    | 'library';
  width?: string;
  height?: string;
};

export default function Logo({ name, width, height }: TIcons) {
  return name === 'withname' ? (
    <svg
      width="173"
      height="75"
      viewBox="0 0 353 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2_53"
        maskUnits="userSpaceOnUse"
        x="0"
        y="16"
        width="17"
        height="18"
      >
        <path
          d="M8.45663 16.0011L12.4795 24.6054L16.5024 33.2098L8.45852 33.2106L0.414732 33.2115L4.43568 24.6063L8.45663 16.0011Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2_53)">
        <path
          d="M16.5005 16.0002L0.412842 16.002L0.414732 33.2115L16.5024 33.2097L16.5005 16.0002Z"
          fill="url(#paint0_linear_2_53)"
        />
      </g>
      <mask
        id="mask1_2_53"
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="69"
        height="70"
      >
        <path
          d="M37.429 24.7614C44.1155 24.7614 53.6005 28.2971 62.307 34.0691L72.4128 11.7287C62.7735 4.28123 50.0232 0 37.7412 0C25.6409 0 15.531 4.61909 9.62204 13.2707L12.0694 18.3461L20.1769 35.1601H4.02661C4.65176 54.3131 17.6244 60.8181 33.8536 65.9041C37.6955 67.1956 40.7484 68.2421 42.9745 69.3319C35.0643 58.5 30.6198 44.5462 30.5884 29.9918C30.5884 29.9854 30.5884 29.9789 30.5884 29.9725V29.6009C30.5883 26.8085 32.9206 24.7614 37.429 24.7614Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_2_53)">
        <path
          d="M72.4128 0.000213623H4.0266V69.3322H72.4128V0.000213623Z"
          fill="#E91E63"
        />
      </g>
      <mask
        id="mask2_2_53"
        maskUnits="userSpaceOnUse"
        x="22"
        y="29"
        width="52"
        height="77"
      >
        <path
          d="M73.4127 70.9311C73.4127 51.3832 60.4629 44.4942 44.1628 38.9091C35.4935 35.7501 30.7715 33.8887 30.7561 29.9922C30.7869 44.5466 35.1416 58.5004 42.892 69.3323C45.7424 70.7564 47.2091 72.2542 47.2091 74.469V74.6548C47.6663 84.0011 44.0442 92.9458 37.6864 98.1707C33.1407 101.906 27.6814 103.342 22.4128 102.379C28.0555 104.136 33.8861 105 39.7437 105C59.853 105 73.4127 93.4583 73.4127 71.3026V70.9311Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_2_53)">
        <path
          d="M73.4127 29.9922H22.4128V105H73.4127V29.9922Z"
          fill="url(#paint1_linear_2_53)"
        />
      </g>
      <mask
        id="mask3_2_53"
        maskUnits="userSpaceOnUse"
        x="0"
        y="68"
        width="47"
        height="35"
      >
        <path
          d="M39.553 80.2386C30.9349 80.2386 20.4682 76.329 10.9267 68.6968L0 90.107C4.8718 94.35 10.1548 97.6644 15.6869 100.078C17.7235 100.967 19.794 101.733 21.89 102.379C27.2125 103.342 32.7278 101.906 37.3201 98.1704C43.7431 92.9456 47.4025 84.0009 46.9405 74.6546V74.8404C46.9405 78.3783 44.6319 80.2386 39.553 80.2386Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_2_53)">
        <path
          d="M47.4024 68.6966H-9.15527e-05V103.342H47.4024V68.6966Z"
          fill="url(#paint2_linear_2_53)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3289 34.8168C30.6467 23.1279 39.7318 13.4734 52.1079 9.54746C57.4282 7.8598 54.5024 8.96667 59.9128 9.54746C52.5659 7.64317 53.2949 5 45.6712 5C30.8635 5 18.4913 9.27537 11.2603 17.2832L14.2552 21.981L24.1768 37.5438H4.41284C5.17787 55.2715 21.0532 61.2925 40.9137 66C30.8192 58.4196 26.0109 46.5056 28.3289 34.8168Z"
        fill="#E91E63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.0959 52.4577C59.4421 64.7969 57.7672 78.9864 50.734 89.4127C43.7007 99.8391 32.4423 104.822 21.4128 102.391C27.1661 104.14 33.111 105 39.0835 105C59.5872 105 73.4128 93.508 73.4128 71.448V71.0781C73.4128 53.656 62.8334 46.3328 48.6945 41C51.3765 44.2457 53.5659 48.1139 55.0959 52.4577Z"
        fill="#E91E63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7167 80.581C40.5696 86.56 38.9453 92.6571 35.3375 97.0186C31.7297 101.38 26.5648 103.491 21.4128 102.71C26.9285 103.672 32.6439 102.237 37.4028 98.5032C44.0589 93.2811 47.8511 84.3412 47.3723 75V75.1857C47.3723 78.7217 44.98 80.581 39.7167 80.581Z"
        fill="#E91E63"
      />
      <path
        d="M90.9113 96H82.4482V56.0809H90.9113V74.3418L94.2177 69.6704L105.047 56.0809H114.42L100.497 73.7654L114.541 96H104.925L94.4604 79.1951L90.9113 81.7432V96ZM153.146 96H144.015L141.134 86.5056H126.573L123.692 96H114.561L128.636 55.9292H138.98L153.146 96ZM128.697 79.4075H139.101L134.582 64.817C134.258 63.725 134.015 62.8251 133.853 62.1173C133.307 64.3013 131.589 70.0647 128.697 79.4075ZM193.609 56.0809V96H182.841L165.49 65.818H165.247C165.591 71.2781 165.763 75.0799 165.763 77.2235V96H158.21V56.0809H168.857L186.208 85.9899H186.39C186.127 80.9949 185.996 77.3246 185.996 74.9788V56.0809H193.609ZM215.752 89.0233C223.901 89.0233 227.976 84.6451 227.976 75.8888C227.976 67.3145 224.194 63.0273 216.631 63.0273H212.142V89.0233H215.752ZM216.207 56.0809C222.698 56.0809 227.743 57.7998 231.343 61.2376C234.963 64.6552 236.773 69.5591 236.773 75.9494C236.773 82.3195 234.902 87.2538 231.161 90.7523C227.42 94.2508 222.02 96 214.963 96H203.679V56.0809H216.207ZM253.509 96H245.046V56.0809H253.509V96ZM297.111 96H287.98L285.098 86.5056H270.538L267.657 96H258.526L272.601 55.9292H282.945L297.111 96ZM272.662 79.4075H283.066L278.546 64.817C278.223 63.725 277.98 62.8251 277.818 62.1173C277.272 64.3013 275.553 70.0647 272.662 79.4075ZM336.937 77.1021C336.937 75.8281 336.937 74.817 336.937 74.0688C336.957 73.3003 337.099 70.1962 337.362 64.7563H337.119L326.806 96H319.071L309.455 64.6957H309.212C309.556 71.2679 309.728 75.5147 309.728 77.4358V96H302.175V56.0809H313.671L323.135 86.5966H323.287L333.297 56.0809H344.824V96H336.937V77.1021Z"
        fill="url(#paint3_linear_2_53)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2_53"
          x1="9.62714"
          y1="31.0447"
          x2="5.49919"
          y2="16.1469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E91E63" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_53"
          x1="47.9128"
          y1="29.9922"
          x2="47.9128"
          y2="105"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C000AC" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_53"
          x1="23.7011"
          y1="68.6966"
          x2="23.7011"
          y2="103.342"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C000AC" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_53"
          x1="77.4128"
          y1="70.5"
          x2="352.413"
          y2="70.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E91E63" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
      </defs>
    </svg>
  ) : name === 'logo' ? (
    <svg
      width="34"
      height="45"
      viewBox="0 0 74 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2_117"
        maskUnits="userSpaceOnUse"
        x="0"
        y="16"
        width="17"
        height="18"
      >
        <path
          d="M8.45663 16.0011L12.4795 24.6054L16.5024 33.2098L8.45852 33.2106L0.414732 33.2115L4.43568 24.6063L8.45663 16.0011Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2_117)">
        <path
          d="M16.5005 16.0002L0.412842 16.002L0.414732 33.2115L16.5024 33.2097L16.5005 16.0002Z"
          fill="url(#paint0_linear_2_117)"
        />
      </g>
      <mask
        id="mask1_2_117"
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="69"
        height="70"
      >
        <path
          d="M37.429 24.7614C44.1155 24.7614 53.6005 28.2971 62.307 34.0691L72.4128 11.7287C62.7735 4.28123 50.0232 0 37.7412 0C25.6409 0 15.531 4.61909 9.62204 13.2707L12.0694 18.3461L20.1769 35.1601H4.02661C4.65176 54.3131 17.6244 60.8181 33.8536 65.9041C37.6955 67.1956 40.7484 68.2421 42.9745 69.3319C35.0643 58.5 30.6198 44.5462 30.5884 29.9918C30.5884 29.9854 30.5884 29.9789 30.5884 29.9725V29.6009C30.5883 26.8085 32.9206 24.7614 37.429 24.7614Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_2_117)">
        <path
          d="M72.4128 0.000213623H4.02661V69.3322H72.4128V0.000213623Z"
          fill="#E91E63"
        />
      </g>
      <mask
        id="mask2_2_117"
        maskUnits="userSpaceOnUse"
        x="22"
        y="29"
        width="52"
        height="76"
      >
        <path
          d="M73.4127 70.9311C73.4127 51.3832 60.4629 44.4942 44.1628 38.9091C35.4935 35.7501 30.7715 33.8887 30.7561 29.9922C30.7869 44.5466 35.1416 58.5004 42.892 69.3323C45.7424 70.7564 47.2091 72.2542 47.2091 74.469V74.6548C47.6663 84.0011 44.0442 92.9458 37.6864 98.1707C33.1407 101.906 27.6814 103.342 22.4128 102.379C28.0555 104.136 33.8861 105 39.7437 105C59.853 105 73.4127 93.4583 73.4127 71.3026V70.9311Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_2_117)">
        <path
          d="M73.4127 29.9922H22.4128V105H73.4127V29.9922Z"
          fill="url(#paint1_linear_2_117)"
        />
      </g>
      <mask
        id="mask3_2_117"
        maskUnits="userSpaceOnUse"
        x="0"
        y="68"
        width="47"
        height="35"
      >
        <path
          d="M39.553 80.2386C30.9349 80.2386 20.4682 76.329 10.9267 68.6968L0 90.107C4.8718 94.35 10.1548 97.6644 15.6869 100.078C17.7235 100.967 19.794 101.733 21.89 102.379C27.2125 103.342 32.7278 101.906 37.3201 98.1704C43.7431 92.9456 47.4025 84.0009 46.9405 74.6546V74.8404C46.9405 78.3783 44.6319 80.2386 39.553 80.2386Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_2_117)">
        <path
          d="M47.4024 68.6966H-6.10352e-05V103.342H47.4024V68.6966Z"
          fill="url(#paint2_linear_2_117)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3289 34.8168C30.6467 23.1279 39.7318 13.4734 52.1079 9.54746C57.4282 7.8598 54.5024 8.96667 59.9128 9.54746C52.5659 7.64317 53.2949 5 45.6712 5C30.8635 5 18.4913 9.27537 11.2603 17.2832L14.2552 21.981L24.1768 37.5438H4.41284C5.17787 55.2715 21.0532 61.2925 40.9137 66C30.8192 58.4196 26.0109 46.5056 28.3289 34.8168Z"
        fill="#E91E63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.0959 52.4577C59.4421 64.7969 57.7672 78.9864 50.734 89.4127C43.7007 99.8391 32.4423 104.822 21.4128 102.391C27.1661 104.14 33.111 105 39.0835 105C59.5872 105 73.4128 93.508 73.4128 71.448V71.0781C73.4128 53.656 62.8334 46.3328 48.6945 41C51.3765 44.2457 53.5659 48.1139 55.0959 52.4577Z"
        fill="#E91E63"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2_117"
          x1="9.62714"
          y1="31.0447"
          x2="5.49919"
          y2="16.1469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E91E63" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_117"
          x1="47.9128"
          y1="29.9922"
          x2="47.9128"
          y2="105"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C000AC" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_117"
          x1="23.7012"
          y1="68.6966"
          x2="23.7012"
          y2="103.342"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C000AC" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
      </defs>
    </svg>
  ) : name === 'logoDark' ? (
    // <svg
    //   width={width || '169'}
    //   height={height || '61'}
    //   viewBox="0 0 169 61"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M26.2061 0L47.0015 36.0108H36.601L26.2061 17.7185L14.8984 36.9945L21.1725 34.24H25.8781L25.8016 36.06L10.3458 45.2143H0L26.2061 0Z"
    //     fill="#8D5CC1"
    //   />
    //   <path
    //     d="M23.1509 30.4144L26.0147 25.3262L29.1682 30.4144H23.1509Z"
    //     fill="#8D5CC1"
    //   />
    //   <path
    //     opacity="0.18"
    //     d="M26.0146 25.3262L29.1681 30.4144H26.0146"
    //     fill="url(#paint0_linear_230_245)"
    //   />
    //   <path
    //     d="M85.7231 23.1566V36.7159H83.2746L82.1051 34.7266C79.8147 36.596 76.934 37.5898 73.9782 37.5303C67.5893 37.5303 60.1018 33.0214 60.1018 24.2933C60.1018 15.5653 67.6275 11.0564 74.011 11.0564C78.9789 11.0564 83.2418 13.0786 85.9034 16.5217L81.4656 20.4239C80.5892 19.3482 79.4933 18.4719 78.2511 17.8536C77.0089 17.2353 75.649 16.8893 74.2624 16.8387C68.972 16.8387 66.3104 20.566 66.3104 24.3261C66.3104 28.0863 68.972 31.7808 74.2624 31.7808C78.3449 31.7808 79.9736 29.3323 79.9736 26.7035V23.1566H85.7231Z"
    //     fill="white"
    //   />
    //   <path
    //     d="M74.2623 21.5768C74.6304 21.5539 74.9992 21.6102 75.3437 21.7418C75.6882 21.8735 76.0005 22.0775 76.2595 22.3401C76.5185 22.6027 76.7181 22.9178 76.845 23.2642C76.9719 23.6105 77.023 23.98 76.9949 24.3477C76.9949 25.0776 76.705 25.7775 76.189 26.2935C75.6729 26.8096 74.973 27.0995 74.2432 27.0995C73.5133 27.0995 72.8134 26.8096 72.2974 26.2935C71.7813 25.7775 71.4914 25.0776 71.4914 24.3477C71.4645 23.977 71.5178 23.6048 71.6475 23.2564C71.7773 22.908 71.9805 22.5917 72.2434 22.3288C72.5062 22.066 72.8226 21.8628 73.171 21.733C73.5193 21.6032 73.8915 21.55 74.2623 21.5768Z"
    //     fill="white"
    //   />
    //   <path
    //     d="M101.518 11.887H107.53V36.7322H101.518V11.887ZM123.412 31.726V36.7322H107.513V31.726H123.412Z"
    //     fill="white"
    //   />
    //   <path d="M133.386 18.9045H127.39V36.7323H133.386V18.9045Z" fill="white" />
    //   <path d="M167.995 18.9045H162V36.7323H167.995V18.9045Z" fill="white" />
    //   <path d="M156.728 11.887H137.004V16.8549H156.728V11.887Z" fill="white" />
    //   <path
    //     d="M149.864 20.7571H143.868V36.7267H149.864V20.7571Z"
    //     fill="white"
    //   />
    //   <path
    //     d="M127.439 16.915L130.27 11.887L133.386 16.915H127.439Z"
    //     fill="#8D5CC1"
    //   />
    //   <path
    //     d="M162.049 16.915L164.88 11.887L167.996 16.915H162.049Z"
    //     fill="#8D5CC1"
    //   />
    //   <path
    //     opacity="0.18"
    //     d="M130.271 11.887L133.386 16.915H130.271"
    //     fill="url(#paint1_linear_230_245)"
    //   />
    //   <path
    //     opacity="0.18"
    //     d="M164.88 11.887L167.996 16.915H164.88"
    //     fill="url(#paint2_linear_230_245)"
    //   />
    //   <path
    //     d="M96.1946 18.9045H90.1992V36.7323H96.1946V18.9045Z"
    //     fill="white"
    //   />
    //   <path
    //     d="M90.2209 16.915L93.0574 11.887L96.1726 16.915H90.2209Z"
    //     fill="#8D5CC1"
    //   />
    //   <path
    //     opacity="0.18"
    //     d="M93.0574 11.887L96.1726 16.915H93.0574"
    //     fill="url(#paint3_linear_230_245)"
    //   />
    //   <path
    //     d="M56.1011 16.0735H50.1057V36.7323H56.1011V16.0735Z"
    //     fill="white"
    //   />
    //   <path
    //     d="M50.1277 13.5867L52.9642 8.55859L56.0794 13.5867H50.1277Z"
    //     fill="#8D5CC1"
    //   />
    //   <path
    //     opacity="0.18"
    //     d="M52.9641 8.55859L56.0793 13.5867H52.9641"
    //     fill="url(#paint4_linear_230_245)"
    //   />
    //   <path
    //     opacity="0.18"
    //     d="M25.8781 34.2399L0 45.2142L8.42201 30.6875L17.7294 32.1686L14.8984 36.9944L21.1725 34.2399H25.8781Z"
    //     fill="url(#paint5_linear_230_245)"
    //   />
    //   <path
    //     d="M51.4671 55.3058C52.9313 55.3058 53.9562 54.8247 54.8556 53.8835L55.6504 55.6404C54.5628 56.6444 52.994 57.1673 51.5926 57.1673C47.8486 57.1673 44.8157 54.1763 44.8157 50.495C44.8157 46.8137 47.8486 43.8436 51.5926 43.8436C52.994 43.8436 54.5628 44.3456 55.6504 45.3496L54.8556 47.0856C53.9562 46.1862 52.9313 45.7052 51.4671 45.7052C48.8108 45.7052 46.6564 47.8596 46.6564 50.495C46.6564 53.1514 48.8108 55.3058 51.4671 55.3058ZM65.152 50.495C65.152 47.002 67.8502 44.1364 71.2805 43.8645V45.7261C68.8542 45.998 66.9926 48.006 66.9926 50.495C66.9926 53.005 68.8542 55.0129 71.2805 55.2849V57.1464C67.8502 56.8745 65.152 54.009 65.152 50.495ZM72.3263 43.8645C75.7566 44.1364 78.4757 47.002 78.4757 50.495C78.4757 54.009 75.7566 56.8745 72.3263 57.1464V55.2849C74.7526 55.0129 76.6141 53.005 76.6141 50.495C76.6141 48.006 74.7526 45.998 72.3263 45.7261V43.8645ZM92.8415 43.99C96.2299 43.99 97.7987 45.7052 97.7987 48.1524C97.7987 50.5996 96.1672 52.5239 93.4271 52.5239H90.6662V57.0209H88.8255V50.6623H93.448C94.8076 50.6623 95.9371 49.9512 95.9371 48.2779C95.9371 46.5627 94.6821 45.8516 93.3226 45.8516H88.8255V43.99H92.8415ZM110.152 57.0209H108.311V43.99H110.152V57.0209ZM121.457 57.0209V43.99H123.298V57.0209H121.457ZM128.569 57.0209H124.365V55.1594H128.569V57.0209ZM137.367 50.495C137.367 47.002 140.065 44.1364 143.495 43.8645V45.7261C141.069 45.998 139.207 48.006 139.207 50.495C139.207 53.005 141.069 55.0129 143.495 55.2849V57.1464C140.065 56.8745 137.367 54.009 137.367 50.495ZM144.541 43.8645C147.971 44.1364 150.69 47.002 150.69 50.495C150.69 54.009 147.971 56.8745 144.541 57.1464V55.2849C146.967 55.0129 148.829 53.005 148.829 50.495C148.829 48.006 146.967 45.998 144.541 45.7261V43.8645ZM168.125 45.8307H159.466V43.99H168.125V45.8307ZM164.716 57.0209H162.854V46.8765H164.716V57.0209Z"
    //     fill="white"
    //   />
    //   <defs>
    //     <linearGradient
    //       id="paint0_linear_230_245"
    //       x1="26.113"
    //       y1="25.5065"
    //       x2="27.4411"
    //       y2="29.9061"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#00FF87" stopOpacity="0" />
    //       <stop offset="0.13" stopColor="#00AC5B" stopOpacity="0.33" />
    //       <stop offset="1" />
    //     </linearGradient>
    //     <linearGradient
    //       id="paint1_linear_230_245"
    //       x1="130.363"
    //       y1="12.0673"
    //       x2="131.681"
    //       y2="16.4177"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#00FF87" stopOpacity="0" />
    //       <stop offset="0.13" stopColor="#00AC5B" stopOpacity="0.33" />
    //       <stop offset="1" />
    //     </linearGradient>
    //     <linearGradient
    //       id="paint2_linear_230_245"
    //       x1="164.973"
    //       y1="12.0673"
    //       x2="166.29"
    //       y2="16.4177"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#00FF87" stopOpacity="0" />
    //       <stop offset="0.13" stopColor="#00AC5B" stopOpacity="0.33" />
    //       <stop offset="1" />
    //     </linearGradient>
    //     <linearGradient
    //       id="paint3_linear_230_245"
    //       x1="93.1503"
    //       y1="12.0673"
    //       x2="94.462"
    //       y2="16.4177"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#00FF87" stopOpacity="0" />
    //       <stop offset="0.13" stopColor="#00AC5B" stopOpacity="0.33" />
    //       <stop offset="1" />
    //     </linearGradient>
    //     <linearGradient
    //       id="paint4_linear_230_245"
    //       x1="53.0516"
    //       y1="8.73348"
    //       x2="54.3687"
    //       y2="13.0893"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#00FF87" stopOpacity="0" />
    //       <stop offset="0.13" stopColor="#00AC5B" stopOpacity="0.33" />
    //       <stop offset="1" />
    //     </linearGradient>
    //     <linearGradient
    //       id="paint5_linear_230_245"
    //       x1="10.0616"
    //       y1="30.1902"
    //       x2="13.379"
    //       y2="41.1809"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#00FF87" stopOpacity="0" />
    //       <stop offset="1" />
    //     </linearGradient>
    //   </defs>
    // </svg>
    <svg
      width="173"
      height="75"
      viewBox="0 0 353 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2_53"
        maskUnits="userSpaceOnUse"
        x="0"
        y="16"
        width="17"
        height="18"
      >
        <path
          d="M8.45663 16.0011L12.4795 24.6054L16.5024 33.2098L8.45852 33.2106L0.414732 33.2115L4.43568 24.6063L8.45663 16.0011Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2_53)">
        <path
          d="M16.5005 16.0002L0.412842 16.002L0.414732 33.2115L16.5024 33.2097L16.5005 16.0002Z"
          fill="url(#paint0_linear_2_53)"
        />
      </g>
      <mask
        id="mask1_2_53"
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="69"
        height="70"
      >
        <path
          d="M37.429 24.7614C44.1155 24.7614 53.6005 28.2971 62.307 34.0691L72.4128 11.7287C62.7735 4.28123 50.0232 0 37.7412 0C25.6409 0 15.531 4.61909 9.62204 13.2707L12.0694 18.3461L20.1769 35.1601H4.02661C4.65176 54.3131 17.6244 60.8181 33.8536 65.9041C37.6955 67.1956 40.7484 68.2421 42.9745 69.3319C35.0643 58.5 30.6198 44.5462 30.5884 29.9918C30.5884 29.9854 30.5884 29.9789 30.5884 29.9725V29.6009C30.5883 26.8085 32.9206 24.7614 37.429 24.7614Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_2_53)">
        <path
          d="M72.4128 0.000213623H4.0266V69.3322H72.4128V0.000213623Z"
          fill="#E91E63"
        />
      </g>
      <mask
        id="mask2_2_53"
        maskUnits="userSpaceOnUse"
        x="22"
        y="29"
        width="52"
        height="77"
      >
        <path
          d="M73.4127 70.9311C73.4127 51.3832 60.4629 44.4942 44.1628 38.9091C35.4935 35.7501 30.7715 33.8887 30.7561 29.9922C30.7869 44.5466 35.1416 58.5004 42.892 69.3323C45.7424 70.7564 47.2091 72.2542 47.2091 74.469V74.6548C47.6663 84.0011 44.0442 92.9458 37.6864 98.1707C33.1407 101.906 27.6814 103.342 22.4128 102.379C28.0555 104.136 33.8861 105 39.7437 105C59.853 105 73.4127 93.4583 73.4127 71.3026V70.9311Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_2_53)">
        <path
          d="M73.4127 29.9922H22.4128V105H73.4127V29.9922Z"
          fill="url(#paint1_linear_2_53)"
        />
      </g>
      <mask
        id="mask3_2_53"
        maskUnits="userSpaceOnUse"
        x="0"
        y="68"
        width="47"
        height="35"
      >
        <path
          d="M39.553 80.2386C30.9349 80.2386 20.4682 76.329 10.9267 68.6968L0 90.107C4.8718 94.35 10.1548 97.6644 15.6869 100.078C17.7235 100.967 19.794 101.733 21.89 102.379C27.2125 103.342 32.7278 101.906 37.3201 98.1704C43.7431 92.9456 47.4025 84.0009 46.9405 74.6546V74.8404C46.9405 78.3783 44.6319 80.2386 39.553 80.2386Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_2_53)">
        <path
          d="M47.4024 68.6966H-9.15527e-05V103.342H47.4024V68.6966Z"
          fill="url(#paint2_linear_2_53)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3289 34.8168C30.6467 23.1279 39.7318 13.4734 52.1079 9.54746C57.4282 7.8598 54.5024 8.96667 59.9128 9.54746C52.5659 7.64317 53.2949 5 45.6712 5C30.8635 5 18.4913 9.27537 11.2603 17.2832L14.2552 21.981L24.1768 37.5438H4.41284C5.17787 55.2715 21.0532 61.2925 40.9137 66C30.8192 58.4196 26.0109 46.5056 28.3289 34.8168Z"
        fill="#E91E63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.0959 52.4577C59.4421 64.7969 57.7672 78.9864 50.734 89.4127C43.7007 99.8391 32.4423 104.822 21.4128 102.391C27.1661 104.14 33.111 105 39.0835 105C59.5872 105 73.4128 93.508 73.4128 71.448V71.0781C73.4128 53.656 62.8334 46.3328 48.6945 41C51.3765 44.2457 53.5659 48.1139 55.0959 52.4577Z"
        fill="#E91E63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7167 80.581C40.5696 86.56 38.9453 92.6571 35.3375 97.0186C31.7297 101.38 26.5648 103.491 21.4128 102.71C26.9285 103.672 32.6439 102.237 37.4028 98.5032C44.0589 93.2811 47.8511 84.3412 47.3723 75V75.1857C47.3723 78.7217 44.98 80.581 39.7167 80.581Z"
        fill="#E91E63"
      />
      <path
        d="M90.9113 96H82.4482V56.0809H90.9113V74.3418L94.2177 69.6704L105.047 56.0809H114.42L100.497 73.7654L114.541 96H104.925L94.4604 79.1951L90.9113 81.7432V96ZM153.146 96H144.015L141.134 86.5056H126.573L123.692 96H114.561L128.636 55.9292H138.98L153.146 96ZM128.697 79.4075H139.101L134.582 64.817C134.258 63.725 134.015 62.8251 133.853 62.1173C133.307 64.3013 131.589 70.0647 128.697 79.4075ZM193.609 56.0809V96H182.841L165.49 65.818H165.247C165.591 71.2781 165.763 75.0799 165.763 77.2235V96H158.21V56.0809H168.857L186.208 85.9899H186.39C186.127 80.9949 185.996 77.3246 185.996 74.9788V56.0809H193.609ZM215.752 89.0233C223.901 89.0233 227.976 84.6451 227.976 75.8888C227.976 67.3145 224.194 63.0273 216.631 63.0273H212.142V89.0233H215.752ZM216.207 56.0809C222.698 56.0809 227.743 57.7998 231.343 61.2376C234.963 64.6552 236.773 69.5591 236.773 75.9494C236.773 82.3195 234.902 87.2538 231.161 90.7523C227.42 94.2508 222.02 96 214.963 96H203.679V56.0809H216.207ZM253.509 96H245.046V56.0809H253.509V96ZM297.111 96H287.98L285.098 86.5056H270.538L267.657 96H258.526L272.601 55.9292H282.945L297.111 96ZM272.662 79.4075H283.066L278.546 64.817C278.223 63.725 277.98 62.8251 277.818 62.1173C277.272 64.3013 275.553 70.0647 272.662 79.4075ZM336.937 77.1021C336.937 75.8281 336.937 74.817 336.937 74.0688C336.957 73.3003 337.099 70.1962 337.362 64.7563H337.119L326.806 96H319.071L309.455 64.6957H309.212C309.556 71.2679 309.728 75.5147 309.728 77.4358V96H302.175V56.0809H313.671L323.135 86.5966H323.287L333.297 56.0809H344.824V96H336.937V77.1021Z"
        fill="url(#paint3_linear_2_53)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2_53"
          x1="9.62714"
          y1="31.0447"
          x2="5.49919"
          y2="16.1469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E91E63" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_53"
          x1="47.9128"
          y1="29.9922"
          x2="47.9128"
          y2="105"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C000AC" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_53"
          x1="23.7011"
          y1="68.6966"
          x2="23.7011"
          y2="103.342"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C000AC" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_53"
          x1="77.4128"
          y1="70.5"
          x2="352.413"
          y2="70.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E91E63" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
      </defs>
    </svg>
  ) : name === 'source' ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="19"
      viewBox="0 0 26 19"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.998 1.9C3.998 2.85 2.998 3.8 1.998 3.8C0.998 3.8 0 2.85 0 1.9C0 0.95 0.998 0 1.998 0C2.998 0 3.998 0.95 3.998 1.9ZM3.998 9.5C3.998 10.45 2.998 11.4 1.998 11.4C0.998 11.4 0 10.45 0 9.5C0 8.55 0.998 7.6 1.998 7.6C2.998 7.6 3.998 8.55 3.998 9.5ZM3.998 17.1C3.998 18.05 2.998 19 1.998 19C0.998 19 0 18.05 0 17.1C0 16.15 0.998 15.2 1.998 15.2C2.998 15.2 3.998 16.15 3.998 17.1ZM8.00007 0.860024C7.42569 0.860024 6.96007 1.32565 6.96007 1.90002C6.96007 2.4744 7.42569 2.94002 8.00007 2.94002H24.0001C24.5744 2.94002 25.0401 2.4744 25.0401 1.90002C25.0401 1.32565 24.5744 0.860024 24.0001 0.860024H8.00007ZM8.00007 8.45622C7.42569 8.45622 6.96007 8.92185 6.96007 9.49622C6.96007 10.0706 7.42569 10.5362 8.00007 10.5362H18.0001C18.5744 10.5362 19.0401 10.0706 19.0401 9.49622C19.0401 8.92185 18.5744 8.45622 18.0001 8.45622H8.00007ZM8.00007 16.06C7.42569 16.06 6.96007 16.5256 6.96007 17.1C6.96007 17.6744 7.42569 18.14 8.00007 18.14H24.0001C24.5744 18.14 25.0401 17.6744 25.0401 17.1C25.0401 16.5256 24.5744 16.06 24.0001 16.06H8.00007Z"
        fill="url(#paint0_linear_5_254)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5_254"
          x1="12.52"
          y1="0"
          x2="12.52"
          y2="19"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E91E63" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
      </defs>
    </svg>
  ) : name === 'related' ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
    >
      <path
        d="M24.0485 6.56967L14.4879 1.78934C14.1807 1.63578 13.8193 1.63578 13.5121 1.78934L3.95148 6.56967C3.14742 6.9717 3.14742 8.11912 3.95148 8.52115L13.5121 13.3015C13.8193 13.455 14.1807 13.455 14.4879 13.3015L24.0485 8.52115C24.8526 8.11912 24.8526 6.9717 24.0485 6.56967Z"
        stroke="url(#paint0_linear_18_46)"
        strokeWidth="2.18182"
      />
      <path
        d="M26 13L14.4879 18.7561C14.1807 18.9096 13.8193 18.9096 13.5121 18.7561L2 13"
        stroke="url(#paint1_linear_18_46)"
        strokeWidth="2.18182"
        strokeLinecap="round"
      />
      <path
        d="M26 19L14.4879 24.7561C14.1807 24.9096 13.8193 24.9096 13.5121 24.7561L2 19"
        stroke="url(#paint2_linear_18_46)"
        strokeWidth="2.18182"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_18_46"
          x1="2"
          y1="7.54541"
          x2="26"
          y2="7.54541"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E91E63" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_18_46"
          x1="2"
          y1="16"
          x2="26"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E91E63" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_18_46"
          x1="2"
          y1="22"
          x2="26"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E91E63" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
      </defs>
    </svg>
  ) : name === 'CirclePlus' ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
    >
      <circle
        cx="7.03516"
        cy="7"
        r="6.5625"
        stroke="url(#paint0_linear_5_105)"
        strokeWidth="0.875"
      />
      <path
        d="M4.3429 7.00005H7.0352M7.0352 7.00005H9.72751M7.0352 7.00005V4.30774M7.0352 7.00005V9.69235"
        stroke="url(#paint1_linear_5_105)"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5_105"
          x1="7.03516"
          y1="0"
          x2="7.03516"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C000AC" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5_105"
          x1="7.0352"
          y1="4.30774"
          x2="7.0352"
          y2="9.69235"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C000AC" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
      </defs>
    </svg>
  ) : name === 'rightArrow' ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
    >
      <path
        d="M1 6.04825C0.474361 6.04825 0.0482456 6.47436 0.0482456 7C0.0482456 7.52564 0.474361 7.95175 1 7.95175L1 6.04825ZM13.8554 7.67299C14.2271 7.30131 14.2271 6.69869 13.8554 6.32701L7.79852 0.27008C7.42684 -0.101603 6.82422 -0.101603 6.45254 0.27008C6.08085 0.641763 6.08085 1.24438 6.45254 1.61606L11.8365 7L6.45254 12.3839C6.08085 12.7556 6.08085 13.3582 6.45254 13.7299C6.82422 14.1016 7.42684 14.1016 7.79852 13.7299L13.8554 7.67299ZM1 7.95175L13.1825 7.95175V6.04825L1 6.04825L1 7.95175Z"
        fill="url(#paint0_linear_13_45)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13_45"
          x1="1"
          y1="7.5"
          x2="13.1825"
          y2="7.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C000AC" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
      </defs>
    </svg>
  ) : name === 'topArrow' ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        opacity="0.5"
        d="M7.03506 13.6755C7.03506 14.2012 7.46118 14.6273 7.98682 14.6273C8.51246 14.6273 8.93857 14.2012 8.93857 13.6755L7.03506 13.6755ZM8.65981 0.820089C8.28812 0.448406 7.68551 0.448406 7.31382 0.820089L1.2569 6.87702C0.885213 7.2487 0.885213 7.85132 1.2569 8.223C1.62858 8.59468 2.2312 8.59468 2.60288 8.223L7.98682 2.83906L13.3708 8.223C13.7424 8.59468 14.3451 8.59468 14.7167 8.223C15.0884 7.85132 15.0884 7.2487 14.7167 6.87702L8.65981 0.820089ZM8.93857 13.6755L8.93857 1.49308L7.03506 1.49308L7.03506 13.6755L8.93857 13.6755Z"
        fill="url(#paint0_linear_5_316)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5_316"
          x1="8.48682"
          y1="1.49308"
          x2="8.48682"
          y2="13.6755"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E91E63" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
      </defs>
    </svg>
  ) : name === 'copy' ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.867 8.66967C15 8.34844 15 7.94011 15 7.125C15 6.30989 15 5.90156 14.867 5.58033C14.7791 5.36795 14.6501 5.17497 14.4876 5.01242C14.325 4.84988 14.1321 4.72095 13.9197 4.633C13.5984 4.5 13.1901 4.5 12.375 4.5H7.3C6.32 4.5 5.83 4.5 5.45589 4.69056C5.12631 4.85838 4.85838 5.12631 4.69056 5.45589C4.5 5.82922 4.5 6.31922 4.5 7.3V12.375C4.5 13.1901 4.5 13.5984 4.633 13.9197C4.81111 14.3482 5.15178 14.6897 5.58033 14.867C5.90156 15 6.30989 15 7.125 15C7.94011 15 8.34844 15 8.66967 14.867M14.867 8.66967C14.7791 8.88205 14.6501 9.07503 14.4876 9.23758C14.325 9.40012 14.1321 9.52905 13.9197 9.617C13.5984 9.75 13.1901 9.75 12.375 9.75C11.5599 9.75 11.1516 9.75 10.8303 9.883C10.6179 9.97095 10.425 10.0999 10.2624 10.2624C10.0999 10.425 9.97095 10.6179 9.883 10.8303C9.75 11.1516 9.75 11.5599 9.75 12.375C9.75 13.1901 9.75 13.5984 9.617 13.9197C9.52905 14.1321 9.40012 14.325 9.23758 14.4876C9.07503 14.6501 8.88205 14.7791 8.66967 14.867M14.867 8.66967C14.4487 10.1124 13.6791 11.4289 12.6272 12.5012C11.5752 13.5736 10.2737 14.3683 8.83922 14.8141L8.66967 14.867M11.5 4.5V3.8C11.5 2.82 11.5 2.33 11.3094 1.95589C11.1418 1.62643 10.8742 1.35852 10.5449 1.19056C10.17 1 9.68 1 8.7 1H3.8C2.82 1 2.33 1 1.95589 1.19056C1.62631 1.35838 1.35838 1.62631 1.19056 1.95589C1 2.32922 1 2.81922 1 3.8V8.7C1 9.68 1 10.17 1.19056 10.5441C1.35856 10.8739 1.62611 11.1414 1.95589 11.3094C2.32922 11.5 2.82 11.5 3.80078 11.5H4.5"
        stroke="url(#paint0_linear_14_13)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14_13"
          x1="8"
          y1="1"
          x2="8"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E91E63" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
      </defs>
    </svg>
  ) : name === 'open' ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
    >
      <path
        d="M22 0H4.88889V2.44444H22V0ZM22 8.55556H9.77778V11H22V8.55556ZM22 17.1111H4.88889V19.5556H22V17.1111ZM7.34556 5.88378L5.61733 4.15556L0 9.77289L5.61733 15.3902L7.34556 13.662L3.45644 9.77289L7.34556 5.88378Z"
        fill="url(#paint0_linear_14_25)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14_25"
          x1="11"
          y1="0"
          x2="11"
          y2="19.5556"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C000AC" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
      </defs>
    </svg>
  ) : name === 'close' ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M21.0017 0H3.89062V2.44444H21.0017V0ZM21.0017 8.55556H8.77951V11H21.0017V8.55556ZM21.0017 17.1111H3.89062V19.5556H21.0017V17.1111Z"
        fill="url(#paint0_linear_1477_1125)"
      />
      <path
        d="M-0.00181022 13.6624L1.72642 15.3906L7.34375 9.77329L1.72642 4.15599L-0.0018109 5.88419L3.88731 9.7733L-0.00181022 13.6624Z"
        fill="url(#paint1_linear_1477_1125)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1477_1125"
          x1="10"
          y1="0"
          x2="10"
          y2="19.5556"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C000AC" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1477_1125"
          x1="-3.65625"
          y1="19.5464"
          x2="-3.65625"
          y2="-0.00921209"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C000AC" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
      </defs>
    </svg>
  ) : name === 'search' ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M17 17L13.1396 13.1396M13.1396 13.1396C13.7999 12.4793 14.3237 11.6953 14.6811 10.8326C15.0385 9.96978 15.2224 9.04507 15.2224 8.11121C15.2224 7.17735 15.0385 6.25264 14.6811 5.38987C14.3237 4.5271 13.7999 3.74316 13.1396 3.08283C12.4793 2.42249 11.6953 1.89868 10.8326 1.54131C9.96978 1.18394 9.04507 1 8.11121 1C7.17735 1 6.25264 1.18394 5.38987 1.54131C4.5271 1.89868 3.74316 2.42249 3.08283 3.08283C1.74921 4.41644 1 6.2252 1 8.11121C1 9.99722 1.74921 11.806 3.08283 13.1396C4.41644 14.4732 6.2252 15.2224 8.11121 15.2224C9.99722 15.2224 11.806 14.4732 13.1396 13.1396Z"
        stroke="url(#paint0_linear_14_19)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14_19"
          x1="9"
          y1="1"
          x2="9"
          y2="17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E91E63" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
      </defs>
    </svg>
  ) : name === 'library' ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
    >
      <path
        d="M8 19C6.87667 18.0847 5.63633 17.3623 4.279 16.833C2.921 16.3037 1.49467 15.9877 0 15.885V6.539C1.52933 6.62833 2.98267 6.99333 4.36 7.634C5.73667 8.274 6.95 9.06667 8 10.012C9.05 9.06733 10.2633 8.27467 11.64 7.634C13.0173 6.994 14.4707 6.629 16 6.539V15.885C14.5013 15.9877 13.074 16.3037 11.718 16.833C10.3627 17.3623 9.12333 18.0847 8 19ZM8 17.747C9.05 16.9763 10.1667 16.3547 11.35 15.882C12.5333 15.41 13.75 15.0983 15 14.947V7.662C13.6933 7.87867 12.4463 8.3 11.259 8.926C10.071 9.55267 8.98467 10.3583 8 11.343C7.01533 10.3583 5.929 9.55267 4.741 8.926C3.553 8.29933 2.306 7.878 1 7.662V14.947C2.25 15.0983 3.46667 15.41 4.65 15.882C5.83333 16.3547 6.95 16.9763 8 17.747ZM8 6.462C7.11133 6.462 6.35067 6.14533 5.718 5.512C5.08533 4.88 4.769 4.12 4.769 3.232C4.769 2.34267 5.08567 1.58167 5.719 0.949C6.351 0.316333 7.11133 0 8 0C8.88867 0 9.64933 0.316667 10.282 0.95C10.9147 1.582 11.231 2.34233 11.231 3.231C11.231 4.11967 10.9143 4.88033 10.281 5.513C9.649 6.14567 8.88867 6.462 8 6.462ZM8 5.462C8.614 5.462 9.13933 5.24333 9.576 4.806C10.0127 4.36933 10.231 3.844 10.231 3.23C10.231 2.61667 10.0123 2.09167 9.575 1.655C9.13833 1.21833 8.613 1 7.999 1C7.38567 1 6.86067 1.21867 6.424 1.656C5.98733 2.09267 5.769 2.618 5.769 3.232C5.769 3.84533 5.98767 4.37033 6.425 4.807C6.86167 5.24367 7.387 5.462 8.001 5.462"
        fill="url(#paint0_linear_14_29)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14_29"
          x1="8"
          y1="0"
          x2="8"
          y2="19"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E91E63" />
          <stop offset="1" stopColor="#9747FF" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <></>
  );
}
