import { zodResolver } from '@hookform/resolvers/zod';
import { EyeIcon, EyeOff } from 'lucide-react';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { useNavigate } from 'react-router-dom';
import { Input } from '../ui/input';
import AuthLink from './auth-links';
import { useMyStore } from 'src/context/store';
import SubmitButton from './submit-button';
import { ModalNames } from 'src/@types/types';
import { routes } from 'src/pages';
import { SignInFormData, SignInFormSchema } from 'src/lib/schema/signin';
import { signIn } from 'src/services/authService';
import { toast } from 'sonner';
import { clientRouters } from 'src/pages/clientRouters';

type Props = {
  afterSignInAction?: () => void;
};
const SigninForm: FC<Props> = ({ afterSignInAction }) => {
  const { setUserStatus, toggleModal, setUser } = useMyStore((state) => state);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const onShowPasswordClick = () => {
    setShowPassword((s) => !s);
  };

  // React Hook Form
  const form = useForm<SignInFormData>({
    resolver: zodResolver(SignInFormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onLogin = async (values: SignInFormData) => {
    setLoading(true);
    const res: TResponse = await signIn(values);
    if (res.status) {
      setUserStatus(true);
      if (res.data) {
        setUser(res.data as IUser);
      }
      toggleModal(ModalNames.SIGNIN, false);
      toast.success(res.message);
      setLoading(false);
      navigate('/');
    } else {
      toast.error(res.message);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Form {...form}>
      <form
        className="space-y-2 dark:text-primary"
        onSubmit={form.handleSubmit(onLogin)}
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className="relative pb-5">
              <FormLabel>Email address</FormLabel>
              <FormControl className="py-6">
                <Input
                  {...field}
                  inputMode="email"
                  placeholder="Enter email"
                  autoComplete="email"
                />
              </FormControl>
              <FormMessage className="absolute bottom-0 text-xs" />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem className="relative">
              <FormLabel>Password</FormLabel>
              <div className="flex">
                <FormControl className="rounded-br-none rounded-tr-none py-6">
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    {...field}
                    inputMode="text"
                    placeholder="**********"
                    autoComplete="current-password"
                  />
                </FormControl>
                <button
                  type="button"
                  onClick={onShowPasswordClick}
                  className="flex cursor-pointer items-center justify-center rounded-br-md rounded-tr-md border border-l-0 border-input px-2"
                >
                  {showPassword ? <EyeIcon /> : <EyeOff />}
                </button>
              </div>
              <FormMessage className="absolute bottom-0 text-xs" />
            </FormItem>
          )}
        />
        <div className="pb-4 text-right">
          <AuthLink
            path={clientRouters.signIn}
            modal={ModalNames.FORGOT_PASSWORD}
          >
            Forgot Password ?
          </AuthLink>
        </div>
        <SubmitButton pending={loading}>Sign in</SubmitButton>
        <p className="text-center">
          Don&apos;t have an account?{' '}
          <AuthLink path={routes[0].path} modal={ModalNames.SIGNUP}>
            Sign Up
          </AuthLink>
        </p>
      </form>
    </Form>
  );
};

export default SigninForm;
