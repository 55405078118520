'use client';
import {
  LogOut,
  Menu,
  MoreHorizontal,
  Palette,
  Settings,
  X,
} from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTrigger,
} from '../ui/sheet';
import { Button } from '../ui/button';
import { ModalNames, SidebarItems } from 'src/@types/types';
import { Separator } from '../ui/separator';
import { Drawer, DrawerContent, DrawerTrigger } from '../ui/drawer';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { SidebarButtonSheet as SidebarButton } from './sidbar-button';
import { Logo } from '../icons';
import { useMyStore } from 'src/context/store';
import { useEffect, useState } from 'react';
import { Switch } from '../ui/switch';
import { useTheme } from 'next-themes';
import { signOut } from 'src/services/authService';
import { toast } from 'sonner';

interface MobileSidebarProps {
  sidebarItems: SidebarItems;
}

export function MobileSidebar(props: MobileSidebarProps) {
  const { pathname } = useLocation();
  const {
    toggleDrawer,
    toggleModal,
    drawer,
    user,
    isUserLoggedIn,
    setUserStatus,
    setUser,
  } = useMyStore((state) => state);

  const [showSidebar, setShowSidebar] = useState(false);
  const toggleThread = () => {
    toggleModal(ModalNames.THREAD, true);
    setShowSidebar(false);
  };
  const { setTheme, theme, resolvedTheme } = useTheme();

  const [isDark, setIsDark] = useState(resolvedTheme === 'dark');
  const navigate = useNavigate();

  useEffect(() => {
    setIsDark(resolvedTheme === 'dark');
  }, [resolvedTheme]);

  const changeTheme = () => {
    setIsDark(!isDark);
    const targetTheme = resolvedTheme === 'dark' ? 'light' : 'dark';
    // console.log('theme change', targetTheme);

    setTheme(targetTheme);
  };

  const handleLogout = async () => {
    const res = await signOut();
    if (res.status)
      setUserStatus(false), toast.success(res.message), navigate('/');
    else toast.error(res.message);
  };

  return (
    <>
      <header className="fixed top-0 z-50 flex  h-16 w-full items-center justify-between bg-background px-5">
        <div className="ml-16">
          <Logo
            name={isDark ? 'logoDark' : 'withname'}
            width="130"
            height="51"
          />
        </div>
        <div>
          <Avatar className="h-8 w-8  dark:text-secondary ">
            <AvatarImage src="" alt="profilePic" />
            <AvatarFallback className="dark:!bg-[#8D5CC11A]">
              {user?.userName.charAt(0).toUpperCase() || 'A'}
            </AvatarFallback>
          </Avatar>
        </div>
        <Button
          size="icon"
          variant="ghost"
          className="fixed left-4 top-3 border-2 dark:border-[#8d8d8d] dark:text-[#8d8d8d]"
          onClick={() => setShowSidebar(true)}
        >
          <Menu size={20} />
        </Button>
      </header>
      <Sheet open={showSidebar} onOpenChange={() => setShowSidebar(false)}>
        <SheetContent
          side="left"
          className="bg-white px-3 py-4 dark:bg-foreground"
          hideClose
        >
          <SheetHeader className="flex flex-row items-center justify-between space-y-0">
            <span className="mx-3 text-lg font-semibold text-foreground">
              <Logo name={isDark ? 'logoDark' : 'withname'} />
            </span>
            <SheetClose asChild>
              <Button
                className="absolute -right-10 top-6 h-8 w-8 rounded-none border border-border p-0 text-border dark:border-[#8d8d8d] dark:text-[#8d8d8d]"
                variant="ghost"
              >
                <X size={15} />
              </Button>
            </SheetClose>
          </SheetHeader>
          <div className="h-full">
            <div className="mt-5 flex w-full flex-col gap-1">
              {props.sidebarItems.extras(false, toggleThread)}
              <div className="mt-5 flex w-full flex-col gap-3">
                {props.sidebarItems.links.map((link, idx) => (
                  <Link key={idx} to={link.href}>
                    <SidebarButton
                      variant={pathname === link.href ? 'secondary' : 'ghost'}
                      icon={link.icon}
                      className="w-full dark:text-accent-foreground"
                    >
                      {link.label}
                    </SidebarButton>
                  </Link>
                ))}
              </div>
            </div>
            <div className="absolute bottom-4 left-0 w-full px-1">
              <Separator className="absolute -top-3 left-0 w-full" />
              {/* <Drawer>
              <DrawerTrigger asChild>
                <Button variant="ghost" className="w-full justify-start">
                  <div className="flex w-full items-center justify-between">
                    <div className="flex gap-2">
                      <Avatar className="h-5 w-5">
                        <AvatarImage src="" alt="profilePic"/>
                        <AvatarFallback>
                            {user?.userName.charAt(0).toUpperCase() || 'A'}
                          </AvatarFallback>
                      </Avatar>
                      <span>{user?.userName}</span>
                    </div>
                    <MoreHorizontal size={20} />
                  </div>
                </Button>
              </DrawerTrigger>
              <DrawerContent className="mb-2 p-2">
                <div className="mt-2 flex flex-col space-y-2">
                  <Link to="/">
                  <div className="flex h-9 w-full items-center justify-between gap-2 whitespace-nowrap rounded-md text-sm font-medium text-primary-foreground ring-offset-background transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2  disabled:pointer-events-none disabled:opacity-50">
                    
                    <SidebarButton size="sm" icon={Palette} className="w-full dark:text-accent-foreground">
                      Dark
                    </SidebarButton>
                    <Switch
                          checked={isDark}
                          onCheckedChange={changeTheme}
                        />
                        </div>
                  </Link>
                  <SidebarButton size="sm" icon={LogOut} className="w-full"
                        onClick={handleLogout}
                        >
                    Log Out
                  </SidebarButton>
                </div>
              </DrawerContent>
            </Drawer> */}
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant="ghost"
                    className="w-full justify-start font-normal dark:text-white"
                  >
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center gap-2">
                        <Avatar className="h-8 w-8">
                          <AvatarImage src="" alt="profilePic" />
                          <AvatarFallback>
                            {user?.userName.charAt(0).toUpperCase() || 'A'}
                          </AvatarFallback>
                        </Avatar>
                        <span>{user?.userName}</span>
                      </div>
                      <MoreHorizontal size={20} />
                    </div>
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="mb-2 rounded-[1rem] p-2 dark:border-[#333333] dark:bg-foreground dark:text-white">
                  <div className="mt-2 flex flex-col space-y-2">
                    <div className="flex h-9 w-full items-center justify-between gap-2 whitespace-nowrap rounded-md text-sm font-medium text-primary-foreground ring-offset-background transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2  disabled:pointer-events-none disabled:opacity-50">
                      <SidebarButton
                        size="sm"
                        icon={Palette}
                        className="w-full dark:text-accent-foreground"
                      >
                        Dark
                      </SidebarButton>
                      <Switch checked={isDark} onCheckedChange={changeTheme} />
                    </div>
                    <SidebarButton
                      size="sm"
                      icon={LogOut}
                      className="w-full dark:text-accent-foreground"
                      onClick={handleLogout}
                    >
                      Log Out
                    </SidebarButton>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
}
